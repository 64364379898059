
::v-deep {
    .v-date-picker-header__value {
        font-size: 18px !important;
    }
}
@media (min-width: 768px) {
    ::v-deep {
        .v-date-picker-header__value {
            font-size: 24px !important;
        }
    }
}
