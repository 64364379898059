
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }

    tfoot > tr > td {
        border-top: thin solid rgba(0, 0, 0, 0.12);
    }
    tfoot > tr > td:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    th {
        user-select: auto !important;
    }
}
