
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }

    .horizontal-table table {
        position: relative;
        display: flex;

        thead {
            position: fixed;

            display: flex;
            flex: 1 0 auto;
            width: 100px;
            background-color: white;
        }

        tbody {
            display: flex;
            flex: 1 0 auto;
            padding-left: 100px;
        }

        tr {
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
        }

        th,
        td {
            border-right: thin solid rgba(0, 0, 0, 0.12);
            min-width: 100px;
            line-height: 32px !important;

            &:not(:last-of-type) {
                border-bottom: thin solid rgba(0, 0, 0, 0.12);
            }
            &:last-of-type {
                border-bottom: none !important;
            }
        }

        tr:last-of-type:not(:first-of-type) {
            td,
            th {
                border-right: none;
            }
        }

        th {
            user-select: auto !important;
        }
    }
}
