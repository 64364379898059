
::v-deep {
    &.v-calendar-daily {
        border: none !important;
    }
    .v-calendar-daily_head-day:last-of-type {
        border-right: none !important;
    }
    .v-calendar-daily_head-day:last-of-type {
        border-right: none !important;
    }
    .v-calendar-daily__day:last-of-type {
        border-right: none !important;
    }
    .v-calendar-daily__day {
        border-bottom: none !important;
    }

    .v-calendar-daily_head-day-label {
        display: none;
    }
    .v-calendar-daily__scroll-area {
        overflow: auto;
    }
    .v-calendar-daily__head {
        margin-right: 0 !important;
    }

    .v-event-timed-container {
        margin: 0 4px !important;
        user-select: none;
    }
}

.v-event-timed {
    user-select: none;
    -webkit-user-select: none;
}

.v-event-drag-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 6px;
    background: rgba(0, 0, 0, 0.1);
    cursor: ns-resize;

    &::after {
        position: absolute;
        left: 50%;
        bottom: 1px;
        height: 3px;
        border-top: 1px solid rgba(0, 0, 0, 0.5);
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        width: 16px;
        margin-left: -8px;
        opacity: 0.8;
        content: "";
    }
}
